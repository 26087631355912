import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../../../../core/services/loader.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationServiceProxy } from '../../../../services/nhost-api-service';
import { catchError, of, Subject, takeUntil, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit, OnDestroy{
  submitted:boolean=false;
  resetForm:FormGroup=new FormGroup({});
  isForget:boolean=false;
  token:any;
  tokenExpired:boolean=true;
  emailAddress:string='';
  onDestroy$: Subject<void> = new Subject<void>();
  constructor(private router:Router,
     private fb:FormBuilder,
     private loaderService: LoaderService,
     private toastr: ToastrService,
     private loginService: AuthenticationServiceProxy,
     private translate: TranslateService,
     private route: ActivatedRoute){}
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.token = params['id'] || null;
    });
    this.verifyToken();
    this.resetForm = this.fb.group({
     newPassword: ['', [Validators.required]],
     confirmNewPassword: ['', [Validators.required]],
    });
  }

  submit(){
    this.submitted=true;
    if(this.resetForm.value.newPassword !== this.resetForm.value.confirmNewPassword)return
    var request:any={
        emailAddress: this.emailAddress,
        password: this.resetForm.value.newPassword,
        resetToken:this.token
    }
    this.loaderService.hideShowLoader(true);
    this.loginService
      .resetPasswordWithToken(request)
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => {
          this.loaderService.hideShowLoader(false);
          const message = this.translate.instant('TOAST.VERIFY');
          const toastrSuccess = this.translate.instant('TOAST.SUCCESS');
          this.toastr.success(message, toastrSuccess);
          this.resetForm.reset();
          this.submitted=false;
          localStorage.removeItem('token')
          this.router.navigate(['/auth'])
        }),
        catchError(() => {
          const message = this.translate.instant('TOAST.INVALIDEMAIL');
          const toastrError = this.translate.instant('TOAST.ERROR');
          this.toastr.error(message, toastrError);
          this.loaderService.hideShowLoader(false);
          return of(null);
        })
      )
      .subscribe();
  }
  verifyToken(){
    let req:any={
      token:this.token
    }
    if(!this.token) return
    this.loaderService.hideShowLoader(true);
    this.loginService.verifyPasswordResetTokenExpiry(this.token)
    .pipe(
      takeUntil(this.onDestroy$),
      tap((resp:any)=>{
      if(resp.isSuccessful){
        this.tokenExpired=false;
        this.emailAddress=resp.emailAddress;
      }else{
        this.tokenExpired=true;
      }
      this.loaderService.hideShowLoader(false);
    }),
     catchError((error:any)=>{
      this.loaderService.hideShowLoader(false);
      return of(null)
     })
    ).subscribe();
  }
  goToForgetPassword(){
    this.router.navigate(['/forgot-password']);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
