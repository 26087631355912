<div *ngIf="isForget" class="forget-container">
    <div class="forget-card">
        <div class="card-header">
            <div class="d-flex justify-content-start">
                <img class="logo" src="/images/nutricia-logo.png" alt="profile">
            </div>
            <div class="login-title justify-content-start">
                {{ 'FORGETPASSWORD.TITLE' | translate }}
                <br><span class="login-subtitle">{{'FORGETPASSWORD.SUBTITLE' | translate}}</span>
            </div>
        </div>
        <div class="forget-body">
            <form [formGroup]="forgetForm">
                <div class="col-12 form-group">
                    <label class="form-label form-field" for="exampleInputEmail1"> {{ 'LOGIN.EMAIL' | translate
                        }}<span class="text-danger">*</span></label>
                    <input formControlName="emailAddress" type="email" class="form-control"
                        name="email" aria-describedby="emailHelp">
                    <small
                        *ngIf="forgetForm.get('emailAddress')?.invalid && forgetForm.get('emailAddress')?.touched || submitted">
                        <small class="text-danger" *ngIf="forgetForm.get('emailAddress')?.errors?.['required']">
                            {{ 'LOGIN.EMAILR' | translate }}
                        </small>
                        <small class="text-danger" *ngIf="forgetForm.get('emailAddress')?.errors?.['email']">
                            {{ 'ERROR.INVALIDEMAIL' | translate }}
                        </small>
                    </small>
                </div>
            </form>

        </div>
        <div class="forget-card-footer mt-4">
            <div class="d-flex justify-content-between">
                <div class="cancel-btn" (click)="cancel()"> {{ 'BUTTON.BACK' | translate }}
                </div>
                <button
                type="submit"
                class="btn btn-primary submit-btn"
                (click)="submit()" [disabled]="forgetForm.invalid">
              {{'BUTTON.SUBMIT' | translate}} 
              </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isForget" class="mail-sent-container">
    <div class="mail-sent-card">
        <div class="card-header">
            <div class="d-flex justify-content-center">
                <img class="logo" src="/images/mail-sent.svg" alt="profile">
            </div>
            <div class="login-title text-center mt-3 mb-2">
                {{ 'FORGETPASSWORD.EMAILSENT' | translate }}
            </div>
            <div class="text-center">
                <span class="login-subtitle">{{'FORGETPASSWORD.EMAIL-SENT-STRING-1' | translate}}</span>
                <span class="user-mail"> {{forgetForm.value.emailAddress}}  </span> <br>
                <span class="login-subtitle">{{'FORGETPASSWORD.EMAIL-SENT-STRING-2' | translate}}</span>
            </div>
        </div>
        <div class="forget-body">
        </div>
        <div class="forget-card-footer mt-4">
        </div>
    </div>
</div>