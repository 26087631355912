<div *ngIf="!tokenExpired"   class="forget-container">
    <div class="forget-card">
        <div class="card-header">
            <div class="d-flex justify-content-start">
                <img class="logo" src="/images/nutricia-logo.png" alt="profile">
            </div>
            <div class="justify-content-start">
                <span class="card-title"> {{ 'RESET.TITLE' | translate }}</span>
                <br><span class="card-subtitle">{{'RESET.SUBTITLE' | translate}}</span>
            </div>
        </div>
        <div class="forget-body">
            <form [formGroup]="resetForm">
                <div class="col-12 form-group">
                    <label class="form-label form-field" for="exampleInputEmail1"> {{ 'RESET.NEWPASSWORD' | translate
                        }}<span class="text-danger">*</span></label>
                    <input formControlName="newPassword" type="password" class="form-control"
                        name="password" aria-describedby="emailHelp">
                    <small
                        *ngIf="resetForm.get('newPassword')?.invalid && resetForm.get('newPassword')?.touched || submitted">
                        <small class="text-danger" *ngIf="resetForm.get('newPassword')?.errors?.['required']">
                            {{ 'RESET.NEWPASSWORDR' | translate }}
                        </small>
                    </small>
                </div>
                <div class="col-12 form-group">
                    <label class="form-label form-field" for="exampleInputEmail1"> {{ 'RESET.CONFIRMNEWPASSOWRD' | translate
                        }}<span class="text-danger">*</span></label>
                    <input formControlName="confirmNewPassword" type="password" class="form-control"
                        name="confirmNewPassword" aria-describedby="emailHelp">
                    <small *ngIf="resetForm.get('confirmNewPassword')?.invalid && resetForm.get('confirmNewPassword')?.touched || submitted">
                        <small class="text-danger" *ngIf="resetForm.get('confirmNewPassword')?.errors?.['required']">
                            {{ 'RESET.CONFIRMPASSWORDR' | translate }}
                        </small>
                        <small class="text-danger" *ngIf="this.resetForm.value.newPassword !== this.resetForm.value.confirmNewPassword">
                            {{ 'RESET.PASSWORD_NOT_MATCH' | translate }}
                        </small>
                    </small>
                </div>
            </form>

        </div>
        <div class="forget-card-footer mt-4">
            <div class="d-flex justify-content-center">
                <button
                type="submit"
                class="btn btn-primary submit-btn"
                (click)="submit()" [disabled]="resetForm.invalid">
              {{'BUTTON.SUBMIT' | translate}} 
              </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="tokenExpired" class="mail-sent-container">
    <div class="mail-sent-card">
        <div class="card-header">
            <div class="d-flex justify-content-center">
                <img class="logo" src="/images/expired.svg" alt="profile">
            </div>
            <div class="login-title text-center mt-3 mb-2">
                {{ 'RESET.TOKEN' | translate }}
            </div>
            <div class="text-center">
                <span class="login-subtitle">{{'RESET.TOKEN_PASSAGE' | translate}}</span>
            </div>
            <div class="d-flex justify-content-center mt-3">
                <button  type="submit"  class="btn btn-primary submit-btn" (click)="goToForgetPassword()"> {{ 'BUTTON.FORGET_PASSWORD' | translate }}</button>
            </div>
        </div>
    </div>
</div>